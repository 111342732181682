import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { usePagination, chunkify } from "@utils";

import { Button, Icon } from "@atoms";

const Pagination = ({
  className: _className,
  component: Component,
  data,
  pageSize,
  rangeLimit,
  showNavigation,
  variants,
}) => {
  const pages = Math.ceil(data?.length / pageSize);
  const [currentPage, setCurrentPage] = useState(1);

  if (pages) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const paginationRange = usePagination({
      pages,
      currentPage,
      rangeLimit,
    });

    const nextPage = () => {
      setCurrentPage(page => (page < pages ? page + 1 : page));
    };
    const prevPage = () => {
      setCurrentPage(page => (page > 1 ? page - 1 : 1));
    };
    // use the number within the button to set the page
    const changePage = event => {
      const pageNumber = Number(event.target.textContent);
      setCurrentPage(pageNumber);
    };
    // chunk the data into pages
    const paginatedData = chunkify([...data], pages, false);

    return (
      <>
        {paginatedData[currentPage - 1]?.map((item, i) => (
          <m.li
            key={item.uid}
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
          >
            <Component order={i + 1} {...item} />
          </m.li>
        ))}
        {pages > 1 && (
          <div className="flex flex-wrap gap-4">
            {/* previous button */}
            {showNavigation && (
              <Button onClick={prevPage} size="xs">
                <Icon
                  name="chevron"
                  className={classNames("h-3 w-3 rotate-180", {})}
                />
              </Button>
            )}
            {/* page number buttons */}
            {paginationRange.map(pageNumber => {
              if (pageNumber === "...") {
                return (
                  <span className="leading-tighter p-2 text-black">
                    {pageNumber}
                  </span>
                );
              }
              return (
                // using vanilla button here to allow for active button styling
                <button
                  // eslint-disable-next-line react/no-array-index-key
                  key={pageNumber}
                  type="button"
                  className={classNames(
                    "leading-tighter py-2 px-4 font-sans font-bold duration-300 hover:bg-carnation hover:text-white",
                    {
                      "text-carnation": currentPage !== pageNumber,
                      "bg-midnight text-white": currentPage === pageNumber,
                    }
                  )}
                  onClick={changePage}
                >
                  <span>{pageNumber}</span>
                </button>
              );
            })}
            {/* next button */}
            {showNavigation && (
              <Button onClick={nextPage} size="xs">
                <Icon name="chevron" className="h-3 w-3" />
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
  return null;
};

Pagination.defaultProps = {
  pageSize: 9,
  rangeLimit: 1,
  showNavigation: true,
};

export default Pagination;
