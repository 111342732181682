import React from "react";
import { Container, Image, Text } from "@atoms";

const CbLogoGrid = ({ images, heading }) => {
  return (
    <Container variant="xs" className="my-8">
      {heading && <Text variant="h6">{heading}</Text>}
      {images.length > 0 && (
        <div className="mt-4 grid grid-cols-3 gap-8 sm:w-4/5 sm:gap-12">
          {images?.map((image, i) => {
            return <Image image={image} objectFit="contain" />;
          })}
        </div>
      )}
    </Container>
  );
};

CbLogoGrid.defaultProps = {};

export default CbLogoGrid;
