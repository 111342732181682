const plugin = require("tailwindcss/plugin");
const typography = require("@tailwindcss/typography");

const colors = {
  black: { DEFAULT: "#232427" },
  offwhite: { DEFAULT: "fbfbfb" },
  white: {
    DEFAULT: "#FFFFFF",
  },
  carnation: {
    DEFAULT: "#FF525A",
  },
  mint: {
    DEFAULT: "#9BFCD9",
  },
  midnight: {
    DEFAULT: "#111850",
    dark: "#191C31",
  },
  gray: {
    DEFAULT: "#8F9094",
    light: "#F4F2F2",
  },
};

module.exports = {
  colors,
  mode: "jit",
  content: ["./src/**/*.{js,svg,pcss}"],
  theme: {
    fontSize: {
      xxs: ".625rem",
      xs: ".75rem",
      sm: ".875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "1.5xl": "1.33rem",
      "2xl": "1.5rem",
      "2.5xl": "1.75rem",
      "3xl": "2rem",
      "3.5xl": "2.25rem",
      "4xl": "2.5rem",
      "4.5xl": "2.75rem",
      "5xl": "3rem",
      "5.5xl": "3.5rem",
      "6xl": "4rem",
      "6.5xl": "4.5rem",
      "7xl": "5rem",
      "8xl": "6rem",
      "9xl": "7rem",
      "10xl": "8rem",
      h1: "5rem",
      h2: "4rem",
      h3: "3rem",
      h4: "2rem",
      h5: "1.75rem",
      inherit: "inherit",
    },
    screens: {
      xxs: { max: "350px" }, // for super small screens
      sm: "700px", // bigger than most phones
      md: "850px",
      lg: "1200px",
      xl: "1400px", // larger than 15" macbook pro
      xxl: "2000px",
    },
    fontFamily: {
      heading: ["Raisonne", "sans-serif"],
      sans: ["Giorgio Sans", "Arial Narrow", "sans-serif"],
      serif: ["Shippori Mincho", "serif"],
    },
    maxWidth: {
      "2xl": "44rem",
      "3xl": "52rem",
      "4xl": "60rem",
      "5xl": "68rem",
      "6xl": "76rem",
      "7xl": "84rem",
      "8xl": "92rem",
    },
    extend: {
      colors,
      transitionProperty: {
        default:
          "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, box-shadow",
        spacing: "margin, padding",
      },
      opacity: {
        15: ".15",
      },
      letterSpacing: {
        // calculated to px value of # @ 16px
        "-3": "-0.1875rem",
        "-2": "-0.125rem",
        "-1": "-0.0625rem",
        0: "0px",
        0.5: "0.03125rem",
        1: "0.0625rem",
        2: "0.125rem",
        3: "0.1875rem",
      },
      minHeight: {
        "1/4-screen": "25vh",
        "1/3-screen": "33.333vh",
        "2/5-screen": "40vh",
        "3/7-screen": "43vh",
        "1/2-screen": "50vh",
        "4/7-screen": "57vh",
        "3/5-screen": "60vh",
        "2/3-screen": "66.666vh",
        "3/4-screen": "75vh",
        "7/8-screen": "87.5vh",
      },
      height: {
        "1/2-screen": "50vh",
      },
      width: {
        "1/1": "100%",
        "1/7": "14%",
        "2/7": "28%",
        "3/7": "42%",
        "4/7": "58%",
        "5/7": "72%",
        "6/7": "86%",
      },
      backgroundImage: {
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
        "gradient-radial-to-tr":
          "radial-gradient(115% 90% at 0% 100%, var(--tw-gradient-stops))",
        "gradient-radial-to-tl":
          "radial-gradient(115% 90% at 100% 100%, var(--tw-gradient-stops))",
        "gradient-radial-to-br":
          "radial-gradient(90% 115% at 0% 0%, var(--tw-gradient-stops))",
        "gradient-radial-to-bl":
          "radial-gradient(90% 115% at 100% 0%, var(--tw-gradient-stops))",
      },
      boxShadow: {
        "inner-full": "inset 0 4rem 8rem 0 rgb(0 0 0);",
        DEFAULT: "0 1px 3px 1px rgba(0, 0, 0, 0.15)",
        md: "0 4px 6px 2px rgba(0, 0, 0, 0.15)",
        lg: "0 10px 15px 5px rgba(0, 0, 0, 0.15)",
        xl: "0 20px 25px 10px rgba(0, 0, 0, 0.15)",
      },
      transformOrigin: {
        "bottom-center": "bottom center",
      },
      typography: theme => ({
        sm: {
          css: {
            lineHeight: 1.5,
            p: {
              margin: "1.5rem 0",
            },
            ol: {
              listStyleType: "decimal-leading-zero",
            },
            ul: {
              paddingLeft: ".875em",
            },
            "ol > li > *:first-child": {
              marginTop: 0,
            },
            "ol > li > *:last-child": {
              marginTop: 0,
            },
            "ol > li + li": {
              marginTop: "1rem",
            },
            "ul > li > *:first-child": {
              marginTop: 0,
            },
            "ul > li > *:last-child": {
              marginTop: 0,
            },
            "ul > li + li": {
              marginTop: "1rem",
            },
            // * these match styles defined in the Text component
            h1: {
              fontSize: "2.5rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h2: {
              fontSize: "2.25rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h3: {
              fontSize: "1.75rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h4: {
              fontSize: "1.33rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h5: {
              fontSize: "1.25rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h6: {
              fontSize: "1.125rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
          },
        },
        base: {
          css: {
            lineHeight: 1.5,
            fontSize: "1rem",
            p: {
              margin: "1.5rem 0",
            },
            ol: {
              listStyleType: "decimal-leading-zero",
            },
            ul: {
              paddingLeft: ".875em",
            },
            "ol > li > *:first-child": {
              marginTop: 0,
            },
            "ol > li > *:last-child": {
              marginTop: 0,
            },
            "ol > li + li": {
              marginTop: "1rem",
            },
            "ul > li > *:first-child": {
              marginTop: 0,
            },
            "ul > li > *:last-child": {
              marginTop: 0,
            },
            "ul > li + li": {
              marginTop: "1rem",
            },
            h1: {
              fontSize: "3rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h2: {
              fontSize: "2.75rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h3: {
              fontSize: "2.25rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h4: {
              fontSize: "1.75rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h5: {
              fontSize: "1.33rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h6: {
              fontSize: "1.25rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
          },
        },
        lg: {
          css: {
            lineHeight: 1.5,
            fontSize: "1rem",
            p: {
              margin: "1.5rem 0",
            },
            ol: {
              listStyleType: "decimal-leading-zero",
            },
            ul: {
              paddingLeft: ".875em",
            },
            "ol > li > *:first-child": {
              marginTop: 0,
            },
            "ol > li > *:last-child": {
              marginTop: 0,
            },
            "ol > li + li": {
              marginTop: "1rem",
            },
            "ul > li > *:first-child": {
              marginTop: 0,
            },
            "ul > li > *:last-child": {
              marginTop: 0,
            },
            "ul > li + li": {
              marginTop: "1rem",
            },
            h1: {
              fontSize: "4rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h2: {
              fontSize: "3.5rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h3: {
              fontSize: "2.75rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h4: {
              fontSize: "2.25rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h5: {
              fontSize: "1.75rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
            h6: {
              fontSize: "1.33rem",
              marginTop: "2rem",
              marginBottom: "1rem",
              lineHeight: 1.125,
            },
          },
        },
        dark: {
          css: {
            "ol > li::marker": {
              fontWeight: "500",
            },
            "--tw-prose-body": theme("colors.white.DEFAULT"),
            "--tw-prose-headings": theme("colors.mint.DEFAULT"),
            "--tw-prose-lead": theme("colors.white.DEFAULT"),
            "--tw-prose-links": theme("colors.mint.DEFAULT"),
            "--tw-prose-bold": "inherit",
            "--tw-prose-counters": theme("colors.mint.DEFAULT"),
            "--tw-prose-bullets": theme("colors.mint.DEFAULT"),
            "--tw-prose-hr": theme("colors.gray.DEFAULT"),
            "--tw-prose-quotes": theme("colors.white.DEFAULT"),
            "--tw-prose-quote-borders": theme("colors.carnation.DEFAULT"),
            "--tw-prose-captions": theme("colors.mint.DEFAULT"),
            "--tw-prose-code": theme("colors.white.DEFAULT"),
            "--tw-prose-pre-code": theme("colors.gray.DEFAULT"),
            "--tw-prose-pre-bg": theme("colors.white.DEFAULT"),
            "--tw-prose-th-borders": "transparent",
            "--tw-prose-td-borders": "transparent",
          },
        },
        DEFAULT: {
          css: {
            strong: {
              fontWeight: 700,
            },
            h1: {
              fontFamily: "Raisonne",
            },
            h2: {
              fontFamily: "Raisonne",
            },
            h3: {
              fontFamily: "Raisonne",
            },
            h4: {
              fontFamily: "Raisonne",
            },
            h5: {
              fontFamily: "Raisonne",
            },
            h6: {
              fontFamily: "Raisonne",
            },
            "ol > li::marker": {
              fontWeight: "500",
            },
            "--tw-prose-body": theme("colors.midnight.DEFAULT"),
            "--tw-prose-headings": theme("colors.midnight.DEFAULT"),
            "--tw-prose-lead": theme("colors.midnight.DEFAULT"),
            "--tw-prose-links": theme("colors.carnation.DEFAULT"),
            "--tw-prose-bold": "inherit",
            "--tw-prose-counters": theme("colors.carnation.DEFAULT"),
            "--tw-prose-bullets": theme("colors.carnation.DEFAULT"),
            "--tw-prose-hr": theme("colors.gray.DEFAULT"),
            "--tw-prose-quotes": theme("colors.midnight.DEFAULT"),
            "--tw-prose-quote-borders": theme("colors.carnation.DEFAULT"),
            "--tw-prose-captions": theme("colors.carnation.DEFAULT"),
            "--tw-prose-code": theme("colors.midnight.DEFAULT"),
            "--tw-prose-pre-code": theme("colors.gray.DEFAULT"),
            "--tw-prose-pre-bg": theme("colors.midnight.DEFAULT"),
            "--tw-prose-th-borders": "transparent",
            "--tw-prose-td-borders": "transparent",
          },
        },
      }),
      fill: theme => theme("colors"),
      stroke: theme => theme("colors"),
    },
  },
  plugins: [
    typography,
    plugin(({ addUtilities, e, theme, variants }) => {
      Object.entries(theme("gap")).forEach(([key, value]) =>
        addUtilities(
          {
            [`.flex-gap-${e(key)}`]: {
              marginTop: `-${value}`,
              marginLeft: `-${value}`,
              "& > *": {
                paddingTop: value,
                paddingLeft: value,
              },
            },
          },
          variants("gap")
        )
      );
    }),
  ],
};
