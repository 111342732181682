import React from "react";
import { Container, Text } from "@atoms";

const CbCopy = ({ copy }) => {
  return (
    <Container variant="xs" className="my-8">
      <Text richText>{copy}</Text>
    </Container>
  );
};

CbCopy.defaultProps = {};

export default CbCopy;
