import React, { useState } from "react";
import { m } from "framer-motion";
import classNames from "classnames";
import { Text, Image, Icon } from "@atoms";
import Bio from "./Bio";

const Person = ({
  bgColor,
  bio,
  cause,
  className: _className,
  company,
  headshot,
  name,
  title,
}) => {
  const [showBio, setShowBio] = useState(false);

  return (
    <>
      <div className={classNames(_className)}>
        <div className="flex flex-col">
          {/* TODO: prevent click if there's no bio */}
          <m.div
            onTap={bio ? () => setShowBio(true) : null}
            className={classNames(
              "relative aspect-square overflow-hidden rounded-full",
              {
                "cursor-pointer": bio,
              }
            )}
          >
            <Image image={headshot} fill className="rounded-full" />
            {!headshot && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-light">
                <Icon name="logoMarkDark" className="h-1/3 w-1/3" />
              </div>
            )}
          </m.div>
          <div className="mt-4 flex flex-col items-center space-y-2 text-center">
            <Text
              variant="h7"
              className={classNames({
                "text-white": bgColor === "midnight" || bgColor === "carnation",
              })}
            >
              {name}
            </Text>
            {title && (
              <span
                className={classNames("text-xs leading-tight", {
                  "text-carnation": bgColor === "white",
                  "text-mint": bgColor === "midnight",
                })}
              >
                {title}
              </span>
            )}
            {cause && (
              <span className="inline-flex space-x-1 text-xs leading-tight">
                {cause.replace(/<[^>]+>/g, "")}
              </span>
            )}
            {company && (
              <Text
                richText
                className={classNames({
                  "prose-dark prose-p:text-xs prose-a:text-xs prose-a:text-white":
                    bgColor === "midnight",
                })}
              >
                {company}
              </Text>
            )}
          </div>
        </div>
      </div>
      {bio && (
        <Bio
          model={[showBio, setShowBio]}
          headshot={headshot}
          name={name}
          title={title}
          bio={bio}
        />
      )}
    </>
  );
};

Person.defaultProps = {};

export default Person;
