import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Icon } from "@atoms";
import TestimonialModal from "./TestimonialModal";

const Location = ({ type, coordinates, className: _className, ...rest }) => {
  const [visible, setVisible] = useState(false);

  const variants = {
    initial: {
      scale: 0,
      opacity: 0,
    },
    enter: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    fadeIn: {
      opacity: 0.6,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    exit: {
      scale: 0,
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };

  if (type === "partner") {
    return (
      <m.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={variants}
        className={classNames(
          "absolute top-0 left-0 z-20 h-2 w-2 rounded-full bg-mint",
          _className
        )}
        style={{ ...coordinates }}
      />
    );
  }

  if (type === "candidate") {
    return (
      <m.div
        initial="initial"
        animate="fadeIn"
        exit="exit"
        variants={variants}
        className={classNames(
          "absolute top-0 left-0 z-10 h-2 w-2 rounded-full bg-carnation opacity-60",
          _className
        )}
        style={{ ...coordinates }}
      />
    );
  }

  if (type === "testimonial" || type === "case study") {
    return (
      <>
        <m.div
          className="absolute top-0 left-0 z-30 h-4 w-4 cursor-pointer"
          whileHover={{
            scale: 1.25,
            transition: {
              duration: 0.3,
              ease: "easeInOut",
            },
          }}
          style={{ ...coordinates }}
          onClick={() => setVisible(!visible)}
        >
          <Icon name="speechBubble" className="w-full" />
        </m.div>
        <TestimonialModal model={[visible, setVisible]} {...rest} />
      </>
    );
  }

  return null;
};

Location.defaultProps = {};

export default Location;
