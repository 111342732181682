import React from "react";
import classNames from "classnames";
import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const ServiceCard = ({
  image,
  logo,
  copy,
  color,
  link,
  className: _className,
}) => {
  return (
    <AppLink
      to={link?.url}
      className={classNames(
        "group border p-8 duration-300 hover:-translate-y-2",
        _className
      )}
    >
      <div className="flex h-full flex-col items-center space-y-4 text-center">
        {image && (
          <div className="relative aspect-square w-full max-w-[14rem]">
            <Image image={image} fill className="rounded-full" />
          </div>
        )}
        {logo && (
          <div className="relative h-auto w-full max-w-[12rem]">
            <Image image={logo} />
          </div>
        )}
        <Text
          richText
          className={classNames({
            "text-white prose-headings:!mt-0 prose-headings:text-mint prose-p:!mt-0 prose-p:text-sm":
              color === "midnight",
          })}
        >
          {copy}
        </Text>
        {link?.url && (
          <ButtonAlt className="mt-auto">{link.text || "Learn More"}</ButtonAlt>
        )}
      </div>
    </AppLink>
  );
};

ServiceCard.defaultProps = {};

export default ServiceCard;
