import React, { useRef, useEffect, useState } from "react";
import { m, useAnimation } from "framer-motion";
import classNames from "classnames";
import { useWindowSize } from "@utils";
import { Icon } from "@atoms";
import tailwindConfig from "@theme";

const Carousel = ({
  children,
  indicators,
  minVisible,
  maxVisible,
  showInactive,
  stretch,
  className: _className,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(maxVisible);
  const slides = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: i,
      });
    }
    return child;
  });

  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  const { screens } = tailwindConfig.theme;

  const handleDrag = (event, info) => {
    const { x, y } = info.offset;
    if (Math.abs(x) > Math.abs(y)) {
      requestAnimationFrame(() => {
        if (x < -slideWidth / slideCount) {
          setCurrentSlide(prevState => {
            if (prevState < slides.length - visibleSlides) {
              return prevState + 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else if (x > slideWidth / slideCount) {
          setCurrentSlide(prevState => {
            if (prevState > 0) {
              return prevState - 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else {
          carouselControls.start({
            x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
          });
        }
      });
    }
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      const screenNumbers = {};
      Object.keys(screens).map(screen => {
        if (typeof screens[screen] === "string") {
          screenNumbers[screen] = parseInt(
            screens[screen].replace("px", ""),
            10
          );
        }
        return true;
      });
      // configure number of slides based on screen size
      const noSlides = {
        sm: minVisible || 1,
        md: 2,
        lg: 3,
        xl: maxVisible,
      };
      // match screen
      const matchedScreen = Object.keys(screenNumbers).find(screen => {
        return width < screenNumbers[screen];
      });
      // return match
      if (matchedScreen && noSlides[matchedScreen] <= maxVisible) {
        return noSlides[matchedScreen];
      }
    }
    return maxVisible;
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  // change slide width on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        setSlideWidth(carouselContainer.current.clientWidth);
      });
    }
  }, [carouselContainer, windowWidth, visibleSlides]);

  // calculate visible slides on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        const newSlides = calculateVisibleSlides(windowWidth);
        setVisibleSlides(newSlides);
      });
    }
  }, [windowWidth]);

  return (
    <>
      <div
        ref={carouselContainer}
        className={classNames("relative w-full", _className)}
      >
        {/* prev button */}
        {slideCount > 1 && (
          <div className="translate-y-50 absolute -left-12 top-0 bottom-0 z-10 hidden transform items-center justify-center md:flex">
            <button
              className={classNames(
                "group flex items-center justify-center rounded-full bg-carnation p-2 text-white transition duration-300",
                {
                  "opacity-20": currentSlide <= 0,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState > 0) {
                    return prevState - 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the previous slide"
            >
              <Icon
                name="chevron"
                className="relative -left-[1px] h-4 w-4 rotate-180 transform transition duration-300 group-hover:-translate-x-1"
              />
            </button>
          </div>
        )}
        <m.div
          animate={carouselControls}
          className="flex items-center"
          transition={{ duration: 0.5, type: "tween" }}
          style={{ width: `${slideCount * 100}%` }}
          drag={slideCount > 1 ? "x" : false}
          onDragEnd={handleDrag}
          dragConstraints={{ left: "-100%", right: 0 }}
          dragDirectionLock
        >
          {slides.map((slide, i) => (
            <div
              key={slide.uid || `${i}--${slideCount}`}
              className={classNames("relative duration-300", {
                "opacity-20":
                  (i < currentSlide || i + 1 > currentSlide + visibleSlides) &&
                  showInactive,
                "opacity-20 sm:opacity-0":
                  (i < currentSlide || i + 1 > currentSlide + visibleSlides) &&
                  !showInactive,
                "md:self-stretch": stretch,
              })}
              style={{ width: `${(1 / visibleSlides / slideCount) * 100}%` }}
            >
              {slide}
            </div>
          ))}
        </m.div>
        {/* next button */}
        {slideCount > 1 && (
          <div className="absolute -right-12 top-0 bottom-0 z-10 hidden items-center justify-center md:flex">
            <button
              className={classNames(
                "group flex items-center justify-center rounded-full bg-carnation p-2 text-white transition duration-300",
                {
                  "opacity-20": currentSlide >= slideCount - visibleSlides,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState < slideCount - visibleSlides) {
                    return prevState + 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the next slide"
            >
              <Icon
                name="chevron"
                className="relative -right-[1px] h-4 w-4 transition duration-300 group-hover:translate-x-1"
              />
            </button>
          </div>
        )}
      </div>
      {/* indicators */}
      {indicators && slideCount > 1 && (
        <ul className="flex items-center justify-center">
          {slides.map((slide, i) => (
            <li
              key={
                slide.uid
                  ? `${slide.uid}--button`
                  : `${i}-${slideCount}--button`
              }
            >
              <button
                type="button"
                onClick={() => setCurrentSlide(i)}
                className={classNames(
                  "mx-1 block h-2 w-2 rounded-full bg-carnation transition duration-300",
                  {
                    "opacity-50": currentSlide !== i,
                  }
                )}
                aria-label={`Go to slide ${i + 1}`}
              >
                <span className="hidden">{i}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

Carousel.defaultProps = {
  maxVisible: 1,
  showInactive: false,
  stretch: false,
};

export default Carousel;
