import React from "react";
import classNames from "classnames";
import { getColor, unwidower } from "@utils";
import { Text, Button } from "@atoms";

const ColumnItem = ({
  button,
  className: _className,
  color: _color,
  descriptor,
  heading,
  order,
  subheading,
  tile,
  useColor,
  transparent,
}) => {
  const color = useColor ? getColor(order) : _color;

  return (
    <div
      className={classNames(
        "flex break-inside-avoid flex-col items-start",
        {
          "p-4": tile,
          "bg-midnight": color === "midnight" && !transparent,
          "bg-carnation": color === "carnation" && !transparent,
          "bg-mint": color === "mint" && !transparent,
          "bg-gray-light": color === "gray" && !transparent,
        },
        _className
      )}
    >
      {heading && (
        <Text
          className={classNames(
            "leading-tighter font-sans text-6xl uppercase tracking-wide lg:text-8xl",
            {
              "text-white": color === "carnation",
              "text-mint": color === "midnight",
              "text-carnation": color === "white",
            }
          )}
        >
          {heading}
        </Text>
      )}
      {subheading && (
        <Text
          variant="h3sans"
          className={classNames({
            "text-white": color === "midnight",
          })}
        >
          {unwidower(subheading)}
        </Text>
      )}
      {descriptor && (
        <Text
          variant="body"
          className={classNames("mt-2 border-t-2 pt-4", {
            "border-white": color === "carnation",
            "border-mint text-white": color === "midnight",
            "border-carnation": color === "white",
            "border-midnight": color === "mint" || !color,
          })}
        >
          {descriptor}
        </Text>
      )}
      {button?.url && (
        <div className="mt-10">
          <Button to={button.url}>{button.text || "Learn More"}</Button>
        </div>
      )}
    </div>
  );
};

ColumnItem.defaultProps = {
  tile: false,
  transparent: false,
};

export default ColumnItem;
