import React, { useState } from "react";
import classNames from "classnames";
import { m, AnimatePresence } from "framer-motion";
import { useAppState } from "@state";

import { Text, Icon, Button } from "@atoms";
import { AppLink } from "@base";

const defaults = {
  duration: 0.2,
  type: "tween",
  ease: "easeInOut",
};

const listVariants = {
  open: {
    height: "auto",
    opacity: 1,
    transition: {
      ...defaults,
    },
  },
  closed: {
    height: 0,
    opacity: 0,
    transition: {
      ...defaults,
    },
  },
};

const MobileLink = ({ text, url, children }) => {
  const [showLinks, setShowLinks] = useState(false);

  return (
    <li className="flex flex-col">
      <span className="flex items-center justify-between py-3">
        <AppLink
          to={url}
          className="underline decoration-carnation/0 decoration-2 underline-offset-2 duration-300 hover:decoration-carnation"
        >
          <Text variant="linkLg">{text}</Text>
        </AppLink>
        {children?.length > 0 && (
          <button
            type="button"
            onClick={() => setShowLinks(!showLinks)}
            className="flex"
          >
            <Icon
              name="chevron"
              className={classNames(
                "ml-1 h-4 w-4 rotate-90 text-carnation duration-300",
                {
                  "-rotate-90": showLinks,
                }
              )}
            />
          </button>
        )}
      </span>
      {children?.length > 0 && (
        <m.ul
          initial="closed"
          animate={showLinks ? "open" : "closed"}
          variants={listVariants}
          className="overflow-hidden"
        >
          {children?.map((subLink, _i) => {
            const { uid: subUid, url: subUrl, text: subText } = subLink;
            return (
              <li className="p-3" key={subUid || `subLink-${subText}--${_i}`}>
                <AppLink
                  to={subUrl}
                  className="underline decoration-carnation/0 decoration-2 underline-offset-2 duration-300 hover:decoration-carnation"
                >
                  <Text variant="link">{subText}</Text>
                </AppLink>
              </li>
            );
          })}
        </m.ul>
      )}
    </li>
  );
};

const MobileHeader = ({ nav, login }) => {
  const [{ layout }] = useAppState();

  // Mobile nav triggers
  const { hideNav } = layout;

  if (nav) {
    return (
      <nav
        aria-label="Mobile navigation"
        aria-live={!hideNav ? "polite" : "off"}
      >
        <AnimatePresence>
          {!hideNav && (
            <m.ul
              className={classNames(
                "absolute left-0 right-0 z-50 w-full space-y-2 bg-white p-4 md:hidden",
                {
                  "pointer-events-none": hideNav,
                }
              )}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {nav?.map((link, i) => {
                const { text, url, button } = link;

                if (button) {
                  return (
                    <li key={`header-link-${text}--${i + 1}`} className="py-3">
                      <Button size="md" to={url}>
                        {text}
                      </Button>
                    </li>
                  );
                }

                return (
                  <MobileLink key={`header-link-${text}--${i + 1}`} {...link} />
                );
              })}

              {login && (
                <li className="py-3">
                  <AppLink className="underline decoration-carnation decoration-2 underline-offset-2 transition duration-300">
                    <Text variant="linkLg">BoardLead login</Text>
                  </AppLink>
                </li>
              )}
            </m.ul>
          )}
        </AnimatePresence>
      </nav>
    );
  }
  return null;
};

MobileHeader.defaultProps = {};

export default MobileHeader;
