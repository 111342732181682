import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";

import { Text, Icon, Button } from "@atoms";

const Accordion = ({
  bgColor,
  column,
  copy,
  heading,
  className: _className,
  link,
  startOpen,
  uid,
}) => {
  const [itemOpen, setItemOpen] = useState(startOpen);

  const defaults = {
    type: "tween",
    duration: 0.3,
    ease: "easeInOut",
  };

  const variants = {
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        ...defaults,
      },
    },
    close: {
      height: 0,
      opacity: 0,
      transition: {
        ...defaults,
      },
    },
  };

  return (
    <div
      key={uid}
      className={classNames(
        "w-full break-inside-avoid border-b-2 pb-2",
        {
          "border-mint": bgColor === "midnight" && column,
          "border-carnation": bgColor === "white" && column,
          "border-white": bgColor === "carnation" && column,
          "border-gray-light": !column,
        },
        _className
      )}
    >
      <button
        type="button"
        className="w-full duration-300"
        onClick={() => setItemOpen(!itemOpen)}
      >
        <div className="relative z-10 flex items-center justify-between">
          <Text
            variant={column ? "h5sans" : "h6"}
            className={classNames("text-left", {
              "text-white": bgColor === "midnight" || bgColor === "carnation",
            })}
          >
            {heading}
          </Text>
          <Icon
            name="chevron"
            className={classNames("ml-8 h-4 w-4 transform duration-300", {
              "rotate-90": itemOpen,
              "text-mint": bgColor === "midnight" && column,
              "text-carnation": bgColor === "white",
              "text-white": bgColor === "carnation" && column,
            })}
          />
        </div>
      </button>
      <m.div
        initial={startOpen ? "open" : "close"}
        animate={itemOpen ? "open" : "close"}
        variants={variants}
        className={classNames(
          "w-full space-y-4 overflow-hidden text-left leading-normal transition-spacing duration-300",
          {
            "my-4": itemOpen,
            "mt-0": !itemOpen,
          }
        )}
      >
        <Text
          richText
          className={{
            "text-white": bgColor === "midnight",
          }}
        >
          {copy}
        </Text>
        {link?.url && (
          <Button to={link.url} size="sm">
            {link.text}
          </Button>
        )}
      </m.div>
    </div>
  );
};

Accordion.defaultProps = {
  column: true,
  showBorders: true,
  startOpen: false,
};

export default Accordion;
