import React from "react";
import { Container, Text, Button } from "@atoms";

const CbCta = ({ className: _className, descriptor, heading, link }) => {
  return (
    <Container variant="xs" className="relative z-20 my-8">
      {/* CTA box */}
      <div className="flex flex-col items-start space-y-8 bg-mint p-6 md:p-12">
        {/* text */}
        <div className="flex w-full flex-col space-y-4 md:pr-12">
          <Text variant="h2">{heading}</Text>
          <Text variant="lg">{descriptor}</Text>
        </div>
        {/* divider */}
        {/* <div className="hidden h-full w-[2px] bg-midnight py-12 md:mx-12 md:block" /> */}
        {/* button */}
        <div className="w-full flex-col items-center justify-center">
          <Button to={link.url} color="carnation">
            {link.text || "Learn More"}
          </Button>
        </div>
      </div>
    </Container>
  );
};

CbCta.defaultProps = {};

export default CbCta;
