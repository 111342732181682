import React from "react";
import classNames from "classnames";
import { Container, Image, Text, ButtonAlt } from "@atoms";

const Testimonial = ({
  color,
  context,
  image,
  link,
  name,
  position,
  quote,
}) => {
  return (
    <Container variant={color === "white" || !color ? "md" : "sm"}>
      <div
        className={classNames(
          "flex flex-wrap items-center gap-12 md:flex-nowrap lg:gap-16 xl:gap-24",
          {
            "bg-midnight p-8 md:p-16": color === "white" || !color,
          }
        )}
      >
        {image && (
          <div className="hidden md:block md:w-2/5">
            <div className="relative aspect-square rounded-full">
              <Image
                image={image}
                fill
                draggable={false}
                className="rounded-full"
              />
            </div>
          </div>
        )}
        <div
          className={classNames("relative flex items-start", {
            "md:w-3/5": image,
            "mx-auto md:w-3/4": !image,
          })}
        >
          <span className="absolute -left-6 -top-1 text-5xl text-mint md:relative md:-top-3 md:-left-2 md:text-8xl">
            &ldquo;
          </span>
          <div className="flex w-full flex-col">
            <Text className="text-sm leading-normal tracking-tight text-white md:text-lg">
              {quote}
            </Text>
            <div className="mt-8 flex items-center">
              {image && (
                <div className="relative mr-4 block h-12 w-12 shrink-0 overflow-hidden rounded-full sm:h-16 sm:w-16 md:hidden">
                  <Image
                    image={image}
                    fill
                    draggable={false}
                    className="rounded-full"
                  />
                </div>
              )}
              <div className="flex flex-col space-y-1">
                <Text className="font-heading text-base text-white md:text-lg">
                  {name}
                </Text>
                <Text className="text-xs leading-tight text-mint md:text-sm">
                  {position}
                </Text>
                <Text className="text-xxs leading-tight text-white md:text-xs">
                  {context}
                </Text>
              </div>
            </div>
            {link?.url && (
              <div className="mt-4">
                <ButtonAlt to={link.url}>
                  {link.text || "Read the Case Study"}
                </ButtonAlt>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

Testimonial.defaultProps = {};

export default Testimonial;
