import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { Text, Image, ButtonAlt, Icon } from "@atoms";
import { AppLink } from "@base";

const ListItem = ({
  url,
  description,
  image,
  title,
  type,
  authors,
  date,
  className: _className,
}) => {
  const typeTitle = type.replace(/([A-Z])/g, " $1");
  const typeLabel = typeTitle.charAt(0).toUpperCase() + typeTitle.slice(1);

  return (
    <AppLink to={url} className="group">
      <div
        className={classNames("flex flex-wrap items-start gap-4", _className)}
      >
        <div
          className={classNames(
            "relative aspect-video w-full flex-shrink-0 sm:aspect-square sm:w-32",
            {
              "bg-gray-light": !image,
            }
          )}
        >
          {!image && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Icon
                name="logoMarkDark"
                className="absolute w-1/5 max-w-[6rem] md:w-1/3"
              />
            </div>
          )}
          <Image image={image} fill />
        </div>
        <div className="flex flex-1 flex-col space-y-4">
          <Text variant="label" className="text-carnation">
            {type === "Default" ? "Resource" : typeLabel}
          </Text>
          <Text
            variant="h7"
            className="duration-300 group-hover:text-carnation"
          >
            {title}
          </Text>
          <div className="mt-4 flex flex-wrap gap-2">
            <span className="w-full text-xs text-carnation md:w-auto">
              by
              <span className="ml-1 text-xs text-midnight">
                {(!!authors.length &&
                  authors.map(author => author.title).join(", ")) ||
                  "The CSP Team"}
              </span>
            </span>
            <span className="w-full text-xs text-carnation md:w-auto">
              on
              <span className="ml-1 text-xs text-midnight">
                {dayjs(date).format("MMMM D, YYYY")}
              </span>
            </span>
          </div>
          <Text variant="sm">{description}</Text>
          <ButtonAlt to={url}>Read</ButtonAlt>
        </div>
      </div>
    </AppLink>
  );
};

ListItem.defaultProps = {};

export default ListItem;
