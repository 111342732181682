import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Button, Text } from "@atoms";
import { json } from "d3";

const FormContainer = ({
  bgColor,
  buttonText,
  children,
  className: _className,
  color,
  errorMessage,
  formState,
  onSubmit,
  successClass,
  successMessage,
}) => {
  // Form States
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formError, setFormError] = useState([]);

  const colors = {
    white: "carnation",
    midnight: "carnation",
    carnation: "midnight",
    mint: "midnight",
  };

  const defaults = {
    duration: 0.3,
    type: "tween",
    ease: "easeInOut",
  };

  const variants = {
    exit: {
      opacity: 0,
      height: 0,
      transition: defaults,
    },
    enter: {
      opacity: 1,
      height: "auto",
      transition: defaults,
    },
  };

  const requiredFields = React.Children.toArray(children)
    ?.map(_f => _f.props)
    ?.map(_f => {
      if (Array.isArray(_f?.children)) {
        return _f?.children?.map(_c => _c?.props);
      }

      return _f;
    })
    ?.flat()
    ?.filter(_f => _f?.required)
    ?.map(_f => _f?.name)
    ?.sort();

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        formState: [form, setForm],
        errorState: [formError, setFormError],
      });
    }
    return child;
  });

  const handleSubmit = async e => {
    e.preventDefault();

    const formKeys = Object.keys(form)
      ?.filter(_f => {
        const isEmpty =
          form[_f]?.length === 0 || form[_f] === false || form[_f] === null;
        return !isEmpty ? _f : false;
      })
      ?.sort();
    const hasRequiredFields =
      formKeys?.length > 0 &&
      requiredFields.every((value, index) => {
        return formKeys?.includes(value);
      });

    if (hasRequiredFields && !success) {
      setFormError([]);

      if (onSubmit) {
        try {
          const res = await onSubmit(e);
          window.dataLayer = window.dataLayer || [];
          if (!res.error) {
            window.dataLayer.push({
              event: "formSuccess",
              url: window.location.pathname,
            });
            setError(null);
            setSuccess(successMessage);
          } else {
            window.dataLayer.push({
              event: "formError",
              url: window.location.pathname,
              error: JSON.stringify(res),
            });
            // eslint-disable-next-line no-console
            console.log("formError", res);
            setSuccess(null);
            setError(
              res.error ||
                "Oops, Something happened with the form submission. Try again later."
            );
          }
        } catch (_e) {
          setError(
            typeof errorMessage === "string"
              ? errorMessage
              : JSON.stringify(errorMessage)
          );
        }
      }
    } else {
      const missingFields = requiredFields?.filter(x => !formKeys.includes(x));
      // ?.concat(formKeys.filter(x => !requiredFields.includes(x)));

      setFormError(missingFields);
      setError(errorMessage);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classNames(_className)}>
      {error && (
        <m.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={variants}
          className="mb-4 block w-full"
        >
          <Text
            variant="h7"
            className={classNames({
              "text-carnation": bgColor === "midnight",
              "text-white": bgColor === "carnation",
            })}
          >
            {error}
          </Text>
        </m.div>
      )}
      {success && (
        <m.div
          initial="exit"
          animate="enter"
          exit="exit"
          variants={variants}
          className={classNames(successClass)}
        >
          <Text variant="h6" className="text-white">
            {success}
          </Text>
        </m.div>
      )}
      {!success && (
        <>
          {childrenWithProps}
          <div className="mt-4 flex w-full justify-center">
            <span className="ml-auto">
              <Button type="submit" color={colors[bgColor]}>
                {buttonText}
              </Button>
            </span>
          </div>
        </>
      )}
    </form>
  );
};

FormContainer.defaultProps = {
  buttonText: "Submit",
  successMessage: "The form has been submitted.",
  errorMessage: "Please fill out all required fields.",
};

export default FormContainer;
