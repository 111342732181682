import React from "react";
import { Container, Image, Text } from "@atoms";

const CbImage = ({ images, caption }) => {
  return (
    <Container variant="xs" className="my-8">
      {images.length === 1 && (
        <div className="relative">
          <Image image={images[0]} />
        </div>
      )}
      {images.length > 1 && (
        <div className="flex flex-wrap gap-4 sm:flex-nowrap">
          {images?.map((image, i) => {
            return <Image image={image} />;
          })}
        </div>
      )}
      {caption && (
        <Text variant="caption" className="mt-4 text-carnation">
          {caption}
        </Text>
      )}
    </Container>
  );
};

CbImage.defaultProps = {};

export default CbImage;
