import React from "react";
import classNames from "classnames";
import { Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const Wrapper = ({ children, className: _className, condition, onClick }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {condition || onClick ? (
        <AppLink
          to={condition}
          onClick={onClick}
          className={classNames(_className)}
        >
          {children}
        </AppLink>
      ) : (
        <div className={classNames(_className)}>{children}</div>
      )}
    </>
  );
};

const InfoCard = ({
  name,
  position,
  heading,
  descriptor,
  link,
  className: _className,
}) => {
  return (
    <Wrapper condition={link?.url} className={classNames("group", _className)}>
      <div
        className={classNames("h-full p-4", {
          "duration-300 group-hover:shadow-md": link?.url,
        })}
      >
        <div className="flex flex-col items-start text-left">
          {name && (
            <span className="inline-flex text-xs text-carnation">
              Served by:<span className="ml-1 text-midnight">{name}</span>
            </span>
          )}
          {position && (
            <span className="mt-2 inline-flex text-xs text-carnation">
              Position:<span className="ml-1 text-midnight">{position}</span>
            </span>
          )}
          <Text variant="h6" className="mt-4">
            {heading}
          </Text>
          <Text variant="sm" className="mt-2">
            {descriptor}
          </Text>
          {link?.url && (
            <ButtonAlt className="mt-2">{link.text || "Read More"}</ButtonAlt>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

InfoCard.defaultProps = {};

export default InfoCard;
