import React from "react";
import { Container, Image, Text, Button, ButtonAlt } from "@atoms";

const CaseStudySlide = ({ image, heading, descriptor, url }) => {
  return (
    <Container>
      <div className="flex flex-wrap items-center gap-8 md:flex-nowrap lg:gap-16 xl:gap-24">
        <div className="relative aspect-square w-3/5 md:w-2/5">
          <Image image={image} fill className="rounded-full" />
        </div>
        <div className="flex-col md:w-3/5">
          <Text variant="h7" className="text-carnation">
            Impact Story
          </Text>
          <Text variant="h4" className="mt-8">
            {heading}
          </Text>
          <Text variant="body" className="mt-4">
            {descriptor}
          </Text>
          <div className="mt-8 flex gap-4">
            {url && (
              <Button to={url} color="carnation">
                Read the Case Study
              </Button>
            )}
            <ButtonAlt to="/case-studies">View All</ButtonAlt>
          </div>
        </div>
      </div>
    </Container>
  );
};

CaseStudySlide.defaultProps = {};

export default CaseStudySlide;
