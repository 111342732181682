import React from "react";
import classNames from "classnames";
import { Container, Share } from "@atoms";

const ShareContainer = ({ share, center }) => {
  return (
    <Container
      variant="xs"
      className={classNames("mt-8 flex", { "justify-center": center })}
    >
      <Share {...share} />
    </Container>
  );
};

ShareContainer.defaultProps = {
  center: false,
};

export default ShareContainer;
