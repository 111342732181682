import React, { useState } from "react";
import classNames from "classnames";
import { Text, Input, Fieldset } from "@atoms";
import FormContainer from "./FormContainer";

const MailchimpForm = ({ heading, descriptor, className: _className }) => {
  const [form, setForm] = useState({});

  const submitForm = async e => {
    try {
      e.preventDefault();

      const data = await fetch("/api/mailchimp", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(form),
      });

      return data.json();
    } catch (error) {
      return error;
    }
  };

  // TODO: if you use this, get from globals
  const roles = [
    "Nonprofit Executive",
    "Board Member",
    "CSR Professional",
    "Human Resources & Talent Development",
    "Nonprofit Staff",
    "Student",
    "Consultant",
    "Other",
  ];

  return (
    <div className={classNames("bg-carnation p-4", _className)}>
      {heading && (
        <Text variant="h5" className="mb-4 text-center text-white">
          {heading}
        </Text>
      )}
      <FormContainer
        bgColor="carnation"
        onSubmit={submitForm}
        formState={[form, setForm]}
        successMessage="You have been subscribed. Thank you!"
      >
        <Fieldset className="flex flex-wrap gap-4">
          <Input
            id="firstName"
            name="firstName"
            label="First Name"
            required
            className="w-full lg:flex-1"
          />
          <Input
            id="lastName"
            name="lastName"
            label="Last Name"
            required
            className="w-full lg:flex-1"
          />
          <Input
            id="email"
            name="email"
            label="Email Address"
            required
            className="w-full"
          />
          <Input
            id="organization"
            name="organization"
            label="Organization"
            required
            className="w-full"
          />
          <Input
            id="city"
            name="city"
            label="City"
            required
            className="w-full"
          />
          <Input
            id="title"
            name="title"
            label="Title"
            required
            className="w-full"
          />
          <Input
            id="role"
            name="role"
            label="Professional Function or Role"
            type="select"
            options={roles}
            className="w-full"
          />
        </Fieldset>
      </FormContainer>
    </div>
  );
};

MailchimpForm.defaultProps = {};

export default MailchimpForm;
