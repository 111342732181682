import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { useAppState } from "@state";
import { Container, ButtonAlt, Image, Text, Video, Icon } from "@atoms";

const ResourceHero = ({
  authors,
  className: _className,
  date,
  descriptor,
  heading,
  hideImage,
  image,
  type,
  video,
}) => {
  // compare dates to check for past events
  const publishDate = dayjs(date).format("MMMM D, YYYY");
  const isPast = currentDate => {
    if (type === "webinar") {
      return dayjs(publishDate).diff(currentDate, "day", true) <= 1;
    }
    return false;
  };

  const [, dispatch] = useAppState();

  // TODO: stop the video on modal close
  const openModal = () => {
    dispatch({
      type: "openModal",
      title: heading,
      description: descriptor,
      content: React.memo(() => (
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Video url={video} placeholder={image} />
        </div>
      )),
    });
  };

  return (
    <Container variant="xs">
      <div className={classNames("flex flex-col", _className)}>
        <ButtonAlt reverse to="/resources">
          All Resources
        </ButtonAlt>
        {!hideImage && (
          <div
            className={classNames("relative mt-8", {
              "aspect-video": image || video,
              "order-1": type !== "whitepaper",
              "order-2": type === "whitepaper",
            })}
          >
            <Image image={image} fill />
            {video && (
              <button
                type="button"
                className="absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-midnight-dark/20 bg-blend-multiply"
                aria-label="Show video"
                onClick={openModal}
              >
                <Icon
                  name="play"
                  className="absolute h-16 w-16 rounded-full border-2 border-white p-2 text-white opacity-80"
                />
              </button>
            )}
          </div>
        )}
        <div
          className={classNames("mt-8 flex flex-col space-y-4", {
            "order-2": type !== "whitepaper",
            "order-1": type === "whitepaper",
          })}
        >
          <Text variant="h2">{heading}</Text>
          <Text variant="xl">{descriptor}</Text>
        </div>
        {/* type and byline */}
        <div className="order-3 mt-8">
          <Text variant="label" className="text-carnation">
            {type}
          </Text>
          <div className="mt-4 flex flex-wrap gap-x-4 gap-y-2">
            <span className="w-full text-xs text-carnation md:w-auto">
              by
              <span className="ml-1 text-xs text-midnight">
                {(!!authors.length &&
                  authors.map(author => author.title).join(", ")) ||
                  "The CSP Team"}
              </span>
            </span>
            {isPast(new Date()) ? (
              <span className="ml-1 text-xs text-carnation">
                This event has passed.
              </span>
            ) : (
              <span className="w-full text-xs text-carnation md:w-auto">
                on
                <span className="ml-1 text-xs text-midnight">
                  {publishDate}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

ResourceHero.defaultProps = {
  hasVideo: false,
  hideImage: false,
};

export default ResourceHero;
