import React from "react";
import classNames from "classnames";
import { Container, Text, Video } from "@atoms";

const CbVideo = ({
  caption,
  bgColor,
  noPadding,
  paddingBottom,
  paddingTop,
  videoUrl,
}) => {
  return (
    <section
      className={classNames({
        "bg-midnight": bgColor === "midnight",
        "bg-white": bgColor === "white",
      })}
    >
      <Container
        variant="xs"
        className={classNames({
          "my-8": noPadding,
          "pt-16 pb-16": !noPadding,
          "md:pt-32": paddingTop,
          "md:pb-32": paddingBottom,
        })}
      >
        {videoUrl && <Video url={videoUrl} />}
        {caption && (
          <Text variant="caption" className="mt-4 text-carnation">
            {caption}
          </Text>
        )}
      </Container>
    </section>
  );
};

CbVideo.defaultProps = {};

export default CbVideo;
