import React from "react";
import { Container, Text, Image, ButtonAlt } from "@atoms";

const CbQuote = ({ quote, attribution, position, link, image }) => {
  return (
    <Container variant="xs" className="my-8">
      <div className="relative flex bg-midnight p-8">
        <span className="relative -top-1 -left-2 text-5xl text-mint md:relative md:-top-3 md:text-8xl">
          &ldquo;
        </span>
        <div className="flex w-full flex-col space-y-4">
          <Text className="text-base leading-normal tracking-tight text-white md:text-lg">
            {quote}
          </Text>
          <div className="flex items-center space-x-4">
            {image && (
              <div className="relative aspect-square h-12 w-12 md:h-16 md:w-16">
                <Image image={image} fill className="rounded-full" />
              </div>
            )}
            <div className="flex flex-col">
              <Text variant="h7" className="text-white">
                {attribution}
              </Text>
              <Text variant="sm" className="text-mint">
                {position}
              </Text>
            </div>
          </div>
          {link?.url && (
            <ButtonAlt to={link.url}>
              {link.text || "Read the Case Study"}
            </ButtonAlt>
          )}
        </div>
      </div>
    </Container>
  );
};

CbQuote.defaultProps = {};

export default CbQuote;
