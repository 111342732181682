import React from "react";
import classNames from "classnames";
import { Icon } from "@atoms";
import { AppLink } from "@base";

const Social = ({ social, className: _className }) => {
  if (social) {
    return (
      <div className="flex space-x-4">
        {social?.map((s, i) => {
          let icon;
          switch (s?.platform) {
            case "linkedin":
              icon = "linkedin";
              break;
            case "twitter":
              icon = "twitter";
              break;
            case "facebook":
              icon = "facebook";
              break;
            case "youtube":
              icon = "youtube";
              break;
            case "instagram":
              icon = "instagram";
              break;
            case "pinterest":
              icon = "pinterest";
              break;
            default:
              icon = undefined;
              break;
          }

          return (
            <AppLink
              key={s?.uid || `social-link--${i}`}
              to={s?.accounturl}
              className={classNames(
                "group flex h-8 w-8 items-center justify-center rounded-full bg-carnation p-2 duration-300 hover:bg-white",
                _className
              )}
            >
              <Icon
                name={icon}
                className="h-full w-full text-white duration-300 group-hover:text-carnation"
              />
            </AppLink>
          );
        })}
      </div>
    );
  }
  return null;
};

Social.defaultProps = {};

export default Social;
