import React from "react";
import classNames from "classnames";
import { m, AnimatePresence } from "framer-motion";
import { Icon, Text, Image, ButtonAlt } from "@atoms";

const TestimonialModal = ({
  context,
  image,
  link,
  model,
  name,
  original_name,
  position,
  quote,
}) => {
  const [visible, setVisible] = model;

  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };

  return (
    <AnimatePresence>
      {visible && (
        <m.div
          initial={{ opacity: 0 }}
          animate="enter"
          exit="exit"
          variants={variants}
          className="pointer-events-none absolute inset-0 z-40 flex items-center justify-center"
        >
          <div className="pointer-events-auto relative w-full max-w-3xl bg-midnight/90 p-12">
            {/* headshot */}
            <div className="absolute -top-8 -left-4 h-16 w-16">
              <Icon name="speechBubble" className="absolute h-full w-full" />
              <div
                className={classNames(
                  "relative top-2 mx-auto aspect-square h-8 w-8 rounded-full",
                  {
                    "flex items-center justify-center": !image,
                  }
                )}
              >
                {!image && (
                  <Icon
                    name="logoMarkDark"
                    className="absolute z-10 mx-auto w-full w-3/4"
                  />
                )}
                {image && (
                  <Image
                    image={{ url: image }}
                    ixParams={{
                      fit: "crop",
                      dpr: 1,
                      q: 30,
                      w: 240,
                      h: 240,
                    }}
                    widthAdjust={0.2}
                    draggable={false}
                    fill
                    className="rounded-full bg-gray-light"
                  />
                )}
              </div>
            </div>
            {/* close button */}
            <button
              type="button"
              onClick={() => setVisible(false)}
              className="absolute top-4 right-4 h-4 w-4 cursor-pointer"
            >
              <Icon name="close" className="text-white" />
            </button>
            {/* quote block */}
            <div className="relative flex">
              <span className="relative -left-6 -top-1 text-5xl text-mint md:relative md:-top-3 md:-left-2 md:text-8xl">
                &ldquo;
              </span>
              <div className="flex w-full flex-col">
                <Text className="text-base font-bold leading-normal tracking-tight text-white md:text-lg">
                  {quote}
                </Text>
                <div className="mt-8 flex flex-col items-start space-y-1">
                  <Text variant="h6" className="text-white">
                    {name || original_name}
                  </Text>
                  <Text variant="sm" className="text-mint">
                    {position}
                  </Text>
                  <Text variant="xs" className="text-white">
                    {context}
                  </Text>
                </div>
                <div className="mt-4">
                  {link && <ButtonAlt to={link}>Read the Case Study</ButtonAlt>}
                </div>
              </div>
            </div>
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
};

TestimonialModal.defaultProps = {};

export default TestimonialModal;
