import React from "react";
import classNames from "classnames";
import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const Wrapper = ({ children, className: _className, condition, onClick }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {condition || onClick ? (
        <AppLink
          to={condition}
          onClick={onClick}
          className={classNames(_className)}
        >
          {children}
        </AppLink>
      ) : (
        <div className={classNames(_className)}>{children}</div>
      )}
    </>
  );
};

const SplitCard = ({
  className: _className,
  image,
  heading,
  descriptor,
  url,
  uri,
  round,
}) => {
  return (
    <Wrapper condition={url || uri} className={classNames("group", _className)}>
      <div className="flex h-full flex-col items-center bg-white shadow-md duration-300 group-hover:bg-midnight lg:flex-row">
        <div
          className={classNames("shrink", {
            "aspect-square w-1/2 p-4 sm:w-2/3 md:w-1/2": round,
            "aspect-video w-full self-stretch lg:aspect-square lg:w-1/2":
              !round,
          })}
        >
          <div className="relative h-full w-full">
            <Image
              image={image}
              fill
              className={classNames({
                "rounded-full": round,
              })}
            />
          </div>
        </div>
        <div
          className={classNames(
            "flex w-full flex-col justify-center space-y-4 p-6 lg:flex-1",
            {
              "items-center text-center lg:items-start lg:text-left": round,
              "items-start text-left": !round,
            }
          )}
        >
          <Text variant="h6" className="duration-300 group-hover:text-white">
            {heading}
          </Text>
          <Text variant="sm" className="duration-300 group-hover:text-white">
            {descriptor}
          </Text>
          {(url || uri) && <ButtonAlt>Read More</ButtonAlt>}
        </div>
      </div>
    </Wrapper>
  );
};

SplitCard.defaultProps = {
  round: false,
};

export default SplitCard;
