import React from "react";
import { Container, Button, ButtonAlt } from "@atoms";

const CbButton = ({ url, text, style, type }) => {
  return (
    <Container variant="xs" className="my-8">
      {style === true ? (
        <Button to={url}>{text}</Button>
      ) : (
        <ButtonAlt to={url}>{text}</ButtonAlt>
      )}
    </Container>
  );
};

CbButton.defaultProps = {};

export default CbButton;
