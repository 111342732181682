import React from "react";
import classNames from "classnames";
import { getColor } from "@utils";
import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const Wrapper = ({ condition, onClick, className: _className, children }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {condition || onClick ? (
        <AppLink
          to={condition}
          onClick={onClick}
          className={classNames(_className)}
        >
          {children}
        </AppLink>
      ) : (
        <div className={classNames(_className)}>{children}</div>
      )}
    </>
  );
};

const ArticleCard = ({
  className: _className,
  color: _color,
  descriptor,
  heading,
  image,
  link,
  order,
  subheading,
  url,
  useColor,
}) => {
  const color = useColor && link?.url ? getColor(order) : _color;
  const linkColor = {
    midnight: "carnation",
    carnation: "white",
    mint: "midnight",
    "gray-light": "carnation",
    white: "carnation",
  };

  return (
    <Wrapper
      condition={url || link?.url}
      className={classNames(
        "group relative flex w-full break-inside-avoid flex-col duration-300",
        {
          "hover:-translate-y-2": url || link?.url,
        },
        _className
      )}
    >
      <div
        className={classNames("relative", {
          "aspect-video bg-white": url || link?.url,
          "aspect-[3/2]": useColor && !link?.url,
        })}
      >
        {useColor && !link?.url && (
          <div className="absolute inset-0 z-10 bg-black opacity-60 bg-blend-multiply" />
        )}
        <Image image={image} fill />
      </div>
      <div
        className={classNames("flex-grow", {
          "absolute inset-x-0 bottom-0 z-20": useColor && !link?.url,
          "bg-midnight": color === "midnight",
          "bg-carnation": color === "carnation",
          "bg-mint": color === "mint",
          "bg-gray-light": color === "gray-light",
          "bg-white": color === "white" || (!color && !useColor),
        })}
      >
        <div className="flex h-full flex-col items-start justify-between p-6 text-left">
          {subheading && (
            <Text
              variant="h7"
              className={classNames("mb-4", {
                "text-carnation": color !== "carnation" && color !== "mint",
                "text-mint": color === "carnation",
                "text-midnight": color === "mint",
              })}
            >
              {subheading}
            </Text>
          )}
          {heading && (
            <Text
              variant={useColor ? "h5" : "h6"}
              className={classNames("mb-2", {
                "text-white":
                  color === "midnight" ||
                  color === "carnation" ||
                  (!link?.url && useColor),
              })}
            >
              {heading}
            </Text>
          )}
          {descriptor && (
            <Text
              variant="body"
              className={classNames({
                "text-white": color === "midnight" || (!link?.url && useColor),
                "mb-4": url || link?.url,
              })}
            >
              {descriptor}
            </Text>
          )}
          {(url || link?.url) && (
            <ButtonAlt className="mt-auto" color={linkColor[color]}>
              {link?.text || "Read"}
            </ButtonAlt>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

ArticleCard.defaultProps = {
  order: undefined,
  useColor: false,
};

export default ArticleCard;
