import React, { useEffect } from "react";

import { useAppState } from "@state";
import { Image, Text, Icon } from "@atoms";

const hasWindow = typeof window !== "undefined";

const BioModal = ({ headshot, name, title, bio }) => {
  const [, dispatch] = useAppState();

  return (
    <div className="relative bg-white p-8 shadow-lg md:p-12">
      <button
        type="button"
        className="absolute top-0 right-0 z-10 h-12 w-12 p-3"
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
        aria-label="Close modal"
      >
        <Icon name="close" className="h-full w-full text-carnation" />
      </button>
      <div className="mt-4 flex flex-col items-center space-y-8">
        <div className="relative aspect-square h-32 w-32 overflow-hidden rounded-full md:h-48 md:w-48">
          <Image image={headshot} fill className="rounded-full" />
        </div>
        <div className="flex flex-col items-center space-y-4 text-center">
          <Text variant="h5">{name}</Text>
          <Text variant="xl">{title}</Text>
        </div>
        {bio && (
          <Text className="mx-auto" richText>
            {bio}
          </Text>
        )}
      </div>
    </div>
  );
};

const Bio = ({ model, headshot, name, title, bio }) => {
  const [{ modal: modalState }, dispatch] = useAppState();
  const { modal: open } = modalState;
  const [showing, setShowing] = model;
  const hash = hasWindow ? window.location?.hash : "";
  const hasHash = hash?.length > 0;
  const returnUrl = hasWindow
    ? `${window.location?.origin}${window.location?.pathname}`
    : null;

  const kebab = string => {
    return string
      ?.replace(/([a-z])([A-Z])/g, "$1-$2")
      ?.replace(/[\s_]+/g, "-")
      ?.replace(",", "")
      ?.toLowerCase();
  };
  const kebabName = kebab(name);
  const hashKebabMatch = hasWindow
    ? hash.replace("#", "") === kebabName
    : false;

  const triggerModal = () => {
    // eslint-disable-next-line no-restricted-globals
    history.pushState({}, "", `#${kebabName}`);

    dispatch({
      type: "openModal",
      title: name,
      description: `${name}'s biography`,
      hideButton: true,
      content: React.memo(() => (
        <BioModal headshot={headshot} name={name} title={title} bio={bio} />
      )),
    });
  };

  // callback to prevent modals from "sticking"
  useEffect(() => {
    if (!open) {
      setShowing(false);
      window.history.pushState({}, "", returnUrl);
    }
  }, [open]);

  // when you click the parent component, open the modal
  useEffect(() => {
    if (showing) {
      triggerModal();
    }
  }, [showing]);

  // open the corresponding modal if a matching hash is found
  useEffect(() => {
    if (hasHash) {
      // the open check prevents modals from swapping if one is already open
      if (!open && hashKebabMatch) {
        setShowing(true);
      }
    }
  }, [showing, hash]);
};

export default Bio;
